import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Checkbox } from './Checkbox';
import { Subject } from './models/Subject';
import { Theme } from './models/Theme';
 
export const ThemeSelector: React.FC<{
    themes: Theme[];
    selectedTheme: Theme | undefined;
    selectedSubjects: Subject[] | undefined;
    onSelectionChanged: (selectedSubjects: Subject[]) => void; 
}> = (props) => {
    const { themes, selectedTheme, selectedSubjects, onSelectionChanged} = props;

    const [showSelector, setShowSelector] = useState<boolean>(false);

    useEffect(() => {
        if (showSelector) {
            document.querySelector('html')?.classList.add('overlay');
        } else {
            document.querySelector('html')?.classList.remove('overlay');
        }
    }, [showSelector]);
    
    const onChangeHandler = (subject: Subject) => {
        const currentSelection = selectedSubjects || [];
        let newSelection: Subject[];

        if (!currentSelection.some(x => x.id === subject.id)) {
            newSelection = [
                ...currentSelection,
                subject,
            ];
        } else {
            newSelection = currentSelection.filter(x => x !== subject);
        }

        onSelectionChanged(newSelection);
    };

    const renderSubjects = (theme: Theme) => {
        if (theme === selectedTheme) {
            return (
                <ul>
                    {theme.subjects.sort((a, b) => a.title.localeCompare(b.title)).map(subject => (
                        <li key={subject.id}>
                            <Checkbox caption={subject.title} checked={selectedSubjects?.some(x => x.id === subject.id) || false} onClick={() => onChangeHandler(subject)} />
                        </li>
                    ))}
                </ul>
            );
        }
    };

    return (
        <>
            <button className="btn-theme-select" onClick={() => setShowSelector(true)}>Selecteer een thema</button>

            <div className={`theme-selector ${showSelector ? '' : 'hide'}`}>
                <h2 onClick={() => setShowSelector(false)}>Selecteer een thema</h2>
                <ul>
                    {themes.sort((a, b) => a.title.localeCompare(b.title)).map(theme => (
                        <li key={theme.id} className={theme === selectedTheme ? 'selected' : ''}>
                            <Link to={`/home/${theme.id}`}>{theme.title}</Link>
                            {renderSubjects(theme)}
                        </li>
                    ))}
                </ul>
                <button onClick={() => setShowSelector(false)}>Opslaan</button>
            </div>
        </>
    );
}
