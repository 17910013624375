import React, { useEffect, useState } from 'react';
import { Checkbox } from './Checkbox';
import { Party } from './models/Party';

export const PartySelector: React.FC<{
    parties: Party[];
    selectedParties: Party[];
    onSelectionChanged: (selectedParties: Party[]) => void; 
}> = (props) => {
    const { parties, selectedParties, onSelectionChanged} = props;

    const [showSelector, setShowSelector] = useState<boolean>(false);
    
    useEffect(() => {
        if (showSelector) {
            document.querySelector('html')?.classList.add('overlay');
        } else {
            document.querySelector('html')?.classList.remove('overlay');
        }
    }, [showSelector]);

    const onChangeHandler = (party: Party) => {
        let newSelection: Party[];

        if (!selectedParties.some(x => x.id === party.id)) {
            newSelection = [
                ...props.selectedParties,
                party,
            ];
        } else {
            newSelection = selectedParties.filter(x => x !== party);
        }
        
        onSelectionChanged(newSelection);
    };

    return (
        <>
            <button className="btn-party-select" onClick={() => setShowSelector(true)}>Selecteer akkoord en partijen</button>

            <div className={`party-selector ${showSelector ? '' : 'hide'}`}>
                <h2 onClick={() => setShowSelector(false)}>Selecteer akkoord en partijen</h2>
                <ul>
                    {parties.sort((a, b) => a.name.localeCompare(b.name)).map(party => (
                        <li key={party.id}>
                            <Checkbox caption={party.name} checked={selectedParties.some(x => x.id === party.id)} onClick={() => onChangeHandler(party)} />
                        </li>
                    ))}
                </ul>
                <button onClick={() => setShowSelector(false)}>Opslaan</button>
            </div>
        </>
    );
};
