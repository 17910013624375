import React from 'react';

export const ElectionCheckbox: React.FC<{
    checked: boolean;
    caption: string;
    onClick: () => void;
}> = (props) => {
    return (
        <div className="election-checkbox" onClick={props.onClick}>
            <div>
                {props.checked && <img src="/election-checkmark.svg" title={props.caption} />}
            </div>
            <span>{props.caption}</span>
        </div>
    );
};