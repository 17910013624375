import { Page } from './models/Page';

export const PageContent: React.FC<{
    page: Page;
}> = (props) => {
    return (
        <div className="page-content">
            <h1>{props.page.title}</h1>
            <div dangerouslySetInnerHTML={{
                __html: props.page.content
            }} />
        </div>
    );
};
