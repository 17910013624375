import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
  
  export const SocialMediaIcons: React.FC = () => {
    return (
        <div className="social-media-icons">
            <LinkedinShareButton 
                url={"https://www.vergelijkpartijen.nl/"}>
                <LinkedinIcon size={36} />
            </LinkedinShareButton>
            <TwitterShareButton 
                url={"https://www.vergelijkpartijen.nl/"}>
                <TwitterIcon size={36} />
            </TwitterShareButton>
            <FacebookShareButton 
                url={"https://www.vergelijkpartijen.nl/"}>
                <FacebookIcon size={36} />
            </FacebookShareButton>
            <WhatsappShareButton 
                url={"https://www.vergelijkpartijen.nl/"}>
                <WhatsappIcon size={36} />
            </WhatsappShareButton>
        </div>
    );
};
