import React, { useContext } from 'react';
import { UserOpinionsDispatch, UserOpinionsDispatchProps } from './contexts/UserOpinionsDispatch';
import { OpinionState } from './models/UserOpinion';

export const AgreeDisagreeOverview: React.FC = () => {
    const userOpinionDispatch = useContext<UserOpinionsDispatchProps>(UserOpinionsDispatch);

    const agreedOpinions = userOpinionDispatch.userOpinions.filter(x => x.opinionState === OpinionState.Agree);
    const disagreedOpinions = userOpinionDispatch.userOpinions.filter(x => x.opinionState === OpinionState.DontAgree);

    const hasOpinions = agreedOpinions.length > 0 || disagreedOpinions.length > 0;

    return (
        <>
            {hasOpinions &&
                <div className="agree-disagree-overview">
                    <div className="agree-overview">
                        <h1>Eens</h1>
                        <ul>
                            {agreedOpinions.map(opinion => (
                                <li key={`${opinion.theme.id}-${opinion.oneLiner.partyId}`}><img src={`/party-logos/${opinion.oneLiner.partyId}.png`} /> {opinion.theme.title}</li>
                            ))}
                        </ul>
                    </div>
                    
                    <div className="disagree-overview">
                        <h1>Oneens</h1>
                        <ul>
                            {disagreedOpinions.map(opinion => (
                                <li key={`${opinion.theme.id}-${opinion.oneLiner.partyId}`}><img src={`/party-logos/${opinion.oneLiner.partyId}.png`} /> {opinion.theme.title}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            }
        </>
    );
};