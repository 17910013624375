import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContent } from './models/AppContent';
import { Page } from './models/Page';
import { Party } from './models/Party';
import { Subject } from './models/Subject';
import { Theme } from './models/Theme';
import { PageContent } from './PageContent';
import { PartySelector } from './PartySelector';
import { ThemeAndSubjectViewer } from './ThemeAndSubjectViewer';
import { ThemeSelector } from './ThemeSelector';
import { AgreeDisagreeOverview } from './AgreeDisagreeOverview';
import { SocialMediaIcons } from './SocialMediaIcons';

const ElectionManifesto: React.FC<{
    party: Party;
}> = (props) => {
    return (
        <li style={{
            backgroundColor: props.party.color
        }}>
            <h4>{props.party.name}</h4>
            <img src={`/party-logos/${props.party.id}.png`} title={props.party.name} alt={`Logo ${props.party.name}`} />
            <a href={props.party.electionManifesto} target="_blank" rel="noreferrer">Ga naar het verkiezingsprogramma van {props.party.name}</a>
        </li>
    );
};

export const PageContainer: React.FC<{
    appContent: AppContent;
}> = (props) => {
    const params: {
        pageId: string;
        themeId: string;
    } = useParams();
    
    if (!params.pageId) {
        params.pageId = 'home';
    }

    const [selectedPage, setSelectedPage] = useState<Page>();
    const [selectedTheme, setSelectedTheme] = useState<Theme>();
    const [selectedSubjects, setSelectedSubjects] = useState<Subject[]>();
    const [selectedParties, setSelectedParties] = useState<Party[]>([]);

    useEffect(() => {        
        const pageId = params.pageId ? params.pageId.toLowerCase() : 'home';

        const page = props.appContent ? props.appContent.pages.find(x => x.id.toLowerCase() === pageId)
                                      : undefined;

        const theme = props.appContent && params.themeId ? props.appContent.themes.find(x => x.id.toLowerCase() === params.themeId.toLowerCase())
                                                         : undefined;

        
        if(selectedPage !== page) {
            setSelectedPage(page);
        }

        if(selectedTheme !== theme) {
            setSelectedTheme(theme);
            setSelectedSubjects(theme?.subjects);
        }
    }, [props.appContent, params, selectedPage, selectedTheme]);

    return (
        <div  id="top">
            {selectedPage &&
                <>
                    <PageContent page={selectedPage} />

                    {selectedPage.displayElectionManifesto &&
                        <ul className="election-manifestos">
                            {props.appContent.parties.sort((a, b) => a.name.localeCompare(b.name)).map(p => <ElectionManifesto party={p} />)}
                        </ul>
                    }

                    {selectedPage?.id !== 'home' &&  
                        <SocialMediaIcons />
                    }
                </>
            }

            {selectedPage?.id === 'home' &&  
                <div className="two-columns">
                    <div className="sidebar">
                        <ThemeSelector  themes={props.appContent.themes} selectedTheme={selectedTheme} selectedSubjects={selectedSubjects} onSelectionChanged={setSelectedSubjects} />

                        <PartySelector parties={props.appContent.parties} selectedParties={selectedParties} onSelectionChanged={setSelectedParties} />

                        <SocialMediaIcons />
                    </div>

                    <div className="spacer"></div>

                    <div className="main">
                        {!selectedTheme && 
                            <div className="select-theme-message">Selecteer aan de linkerkant een thema en selecteer het akkoord en de partijen die je wilt vergelijken.</div>
                        }

                        {selectedTheme &&
                            <ThemeAndSubjectViewer theme={selectedTheme} selectedParties={selectedParties} selectedSubjects={selectedSubjects} />
                        }
                        
                        <AgreeDisagreeOverview />
                        
                        <a href="#top" className="back-to-top">↑ <span>Terug naar boven</span></a>

                        <SocialMediaIcons />
                    </div>
                </div>
            }
        </div>
    );
};
