import React from "react";
import { Opinion } from "./models/Opinion";
import { Party } from "./models/Party";
import { Subject } from "./models/Subject";
import { Theme } from "./models/Theme";
import { ElectionCheckboxChoice } from "./ElectionCheckboxChoice";
import { OneLiner } from './models/OneLiner';

const OpinionEl: React.FC<{
    selectedParties: Party[];
    opinion: Opinion;
}> = (props) => {
    const party = props.selectedParties?.find(x => x.id.toLowerCase() === props.opinion.partyId.toLowerCase());

    return (
        <div className="opinion"
                style={{
                    backgroundColor: party?.color
                }}>
            <h4>{party?.name}</h4>
            <img src={`/party-logos/${party?.id}.png`} title={party?.name} alt={`Logo ${party?.name}`} />
            <p>{props.opinion.opinion}</p>
        </div>
    );
};

const OneLinerEl: React.FC<{
    selectedParties: Party[];
    theme: Theme;
    oneLiner: OneLiner;
}> = (props) => {
    const party = props.selectedParties?.find(x => x.id.toLowerCase() === props.oneLiner.partyId.toLowerCase());

    return (
        <div className="opinion"
                style={{
                    backgroundColor: party?.color
                }}>
            <h4>{party?.name}</h4>
            <img src={`/party-logos/${party?.id}.png`} title={party?.name} alt={`Logo ${party?.name}`} />
            <p className="one-liner">{props.oneLiner.oneLiner}</p>
            <ElectionCheckboxChoice theme={props.theme}
                                    oneLiner={props.oneLiner} />
        </div>
    );
};

export const ThemeAndSubjectViewer: React.FC<{
    theme: Theme | undefined;
    selectedParties: Party[] | undefined;
    selectedSubjects:  Subject[] | undefined;
}> = (props) => {
    const { theme, selectedParties, selectedSubjects } = props;

    const subjectOpinions = (subject: Subject) => {
        return subject.opinions.filter(o => selectedParties?.some(x => x.id.toLowerCase() === o.partyId.toLowerCase()));
    };

    const hasSelectedParties = selectedParties && selectedParties.length > 0;
    const hasSelectedSubjects = selectedSubjects && selectedSubjects.length > 0;

    return (
        <>
            {!theme && 
                <p>Selecteer aan de linkerkant een thema en kies de partijen waarvan je de standpunten met
                elkaar wilt vergelijken.</p>
            }

            {theme &&
                <div className="theme-and-subject-viewer">
                    <h2>{theme.title}</h2>

                    {!hasSelectedParties && 
                        <div className="select-party-message">Selecteer links het akkoord en de partijen die je wilt vergelijken in het thema ‘{theme.title}’.</div>
                    }
                    
                    {hasSelectedParties && selectedParties && 
                        <>
                            {!hasSelectedSubjects && 
                                <div className="select-subject-message">Selecteer links 1 of meer onderwerpen die je wilt vergelijken.</div>
                            }

                            {selectedSubjects && selectedSubjects.sort((a, b) => a.title.localeCompare(b.title)).map(subject => (
                                <div key={subject.id} className="subject">
                                    <h3>{subject.title}</h3>

                                    {subjectOpinions(subject).sort((a, b) => a.partyId.localeCompare(b.partyId)).map(opinion => (
                                        <OpinionEl key={opinion.partyId} opinion={opinion} selectedParties={selectedParties} />
                                    ))}
                                </div>
                            ))}
                            
                            {hasSelectedParties && hasSelectedSubjects && 
                                <>
                                    <h4>Je hebt nu alle plannen gelezen. Waar kies jij voor?</h4>

                                    {theme.oneLiners.filter(x => selectedParties.sort((a, b) => a.name.localeCompare(b.name)).some(p => p.id.toLowerCase() === x.partyId.toLowerCase())).sort((a, b) => a.partyId.localeCompare(b.partyId)).map(oneLiner => (
                                        <OneLinerEl key={oneLiner.partyId} 
                                                    theme={theme}
                                                    oneLiner={oneLiner}
                                                    selectedParties={selectedParties} />
                                    ))}
                                </>
                            }
                        </>
                    }
                </div>
            }
        </>
    );
};
