import React, { useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import { useAxios } from 'use-axios-client';
import { Config } from 'use-axios-client/bin/useBaseAxios';
import { AppContent } from './models/AppContent';
import { PageContainer } from "./PageContainer";
import { UserOpinionsDispatch } from './contexts/UserOpinionsDispatch';

import './App.css';
import { UserOpinion } from './models/UserOpinion';

function App() {
    const [userOpinions, setUserOpinions] = useState<UserOpinion[]>([]);

    const { data, error, loading } = useAxios<AppContent>({
        url: '/data.php',
        //url: '/example-data.json',
    } as Config);
    
    return (
        <UserOpinionsDispatch.Provider value={{
            userOpinions,
            setUserOpinions,
        }}>
            <Router basename="/">
                {loading && <LoadingScreen />}
                {error && <ErrorScreen error={error} />}
                {data && 
                    <Switch>
                        <Route path="/:pageId?/:themeId?">
                            <PageContainer appContent={data} />
                        </Route>
                    </Switch>
                }
            </Router>
        </UserOpinionsDispatch.Provider>
    );
}

const LoadingScreen: React.FC = () => {
    return (
        <div>
            Bezig met het laden van data...
        </div>
    );
};

const ErrorScreen: React.FC<{
    error: any;
}> = (props) => {
    return (
        <div>
            Fout: {props.error.message}
        </div>
    );
};

export default App;
