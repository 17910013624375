import React, { Dispatch, SetStateAction } from "react";
import { UserOpinion } from "../models/UserOpinion";

export interface UserOpinionsDispatchProps {
    userOpinions: UserOpinion[];
    setUserOpinions: Dispatch<SetStateAction<UserOpinion[]>>;
};

export const UserOpinionsDispatch = React.createContext<UserOpinionsDispatchProps>({
    userOpinions: [],
    setUserOpinions: () => {},
});