import { OneLiner } from "./OneLiner";
import { Theme } from "./Theme";

export enum OpinionState {
    Unknown,
    Agree,
    DontAgree,
}

export interface UserOpinion {
    theme: Theme;
    oneLiner: OneLiner;
    opinionState: OpinionState;
}
