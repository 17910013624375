import React, { useCallback, useContext } from 'react';
import { Theme } from './models/Theme';
import { OneLiner } from './models/OneLiner';
import { UserOpinionsDispatch, UserOpinionsDispatchProps } from './contexts/UserOpinionsDispatch';
import { OpinionState, UserOpinion } from './models/UserOpinion';
import { ElectionCheckbox } from './ElectionCheckbox';

export const ElectionCheckboxChoice: React.FC<{
    theme: Theme;
    oneLiner: OneLiner;
}> = (props) => {
    const userOpinionDispatch = useContext<UserOpinionsDispatchProps>(UserOpinionsDispatch);

    const userOpinion = userOpinionDispatch.userOpinions.find(x => x.theme === props.theme
                                                                && x.oneLiner === props.oneLiner);

    const updateUserOpinion = useCallback(function (newOpinionState: OpinionState) {
        if (userOpinion) {
            if (userOpinion.opinionState === newOpinionState) {
                // clear state
                userOpinion.opinionState = OpinionState.Unknown;
            } else {
                // set new state
                userOpinion.opinionState = newOpinionState;
            }

            userOpinionDispatch.setUserOpinions([
                ...userOpinionDispatch.userOpinions
            ]);
        } else {
            userOpinionDispatch.setUserOpinions([
                ...userOpinionDispatch.userOpinions,
                {
                    theme: props.theme,
                    oneLiner: props.oneLiner,
                    opinionState: newOpinionState,
                } as UserOpinion
            ]);
        }
    }, [userOpinionDispatch, props, userOpinion]);

    return (
        <ul className="election-checkbox-choice">
            <li><ElectionCheckbox caption="Eens" checked={userOpinion?.opinionState === OpinionState.Agree} onClick={() => updateUserOpinion(OpinionState.Agree)} /></li>
            <li><ElectionCheckbox caption="Oneens" checked={userOpinion?.opinionState === OpinionState.DontAgree} onClick={() => updateUserOpinion(OpinionState.DontAgree)} /></li>
        </ul>
    );
};